import React, { createContext, useCallback, useState } from 'react'

export const ShoppingCartDisplayContext = createContext(false)

export const ShoppingCartDisplayProvider = ({ children }) => {
  const [cartVisible, setCartVisible] = useState(false)

  const toggleCart = useCallback(() => {
    setCartVisible((prev) => !prev)
  }, [setCartVisible])

  return (
    <ShoppingCartDisplayContext.Provider
      value={{
        cartVisible,
        toggleCart,
      }}
    >
      {children}
    </ShoppingCartDisplayContext.Provider>
  )
}
