import React, { createContext, useCallback, useEffect, useState } from 'react'
import { handleError, isErrorObject } from '../utils'

function removeRestaurantTypes(locations) {
  const output = []
  if (!Array.isArray(locations)) return output
  locations
    .filter((location) => !!location.type && Array.isArray(location.type))
    .forEach((location) => {
      if (location.type.includes('restaurant')) {
        location.type = ['bar']
      }
      output.push(location)
    })
  return output
}

async function _fetchBeerMapData() {
  const response = await fetch('/.netlify/functions/wheretofind', {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  }).catch(handleError)
  const data = await response.json()
  return response.ok ? removeRestaurantTypes(data) : data
}

export const WhereToFindContext = createContext([])

export const WhereToFindProvider = ({ children }) => {
  const [locations, setLocations] = useState([])

  const fetchBeerMapData = useCallback(async () => {
    const data = await _fetchBeerMapData()
    if (!isErrorObject(data)) {
      setLocations(data)
    }
  }, [])

  // load data once per session
  useEffect(() => {
    fetchBeerMapData()
  }, [fetchBeerMapData])

  return (
    <WhereToFindContext.Provider
      value={{
        locations,
        fetchBeerMapData,
      }}
    >
      {children}
    </WhereToFindContext.Provider>
  )
}
