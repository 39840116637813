import { graphql, useStaticQuery } from 'gatsby'

const useTeamImage = () => {
  const query = useStaticQuery(
    graphql`
      query TeamImageQuery {
        image: file(absolutePath: { regex: "/aaron-stellar-beer.jpg/" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
      }
    `,
  )
  query.image.alt = 'Otherwise Co-Founders Aaron Gervais and Stellar Cassidy'
  return query.image
}

export default useTeamImage
