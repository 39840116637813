import { graphql, useStaticQuery } from 'gatsby'

import moment from 'moment'

const useBeerList = () => {
  const query = useStaticQuery(
    graphql`
      query BeerListQuery {
        allMarkdownRemark(
          filter: { frontmatter: { template: { eq: "beer" } } }
          sort: { frontmatter: { title: ASC } }
        ) {
          edges {
            node {
              id
              html
              excerpt(pruneLength: 480, format: HTML)
              frontmatter {
                title
                template
                teaser
                beerMeta {
                  sku
                  styles
                  awards {
                    year
                    medal
                    name
                  }
                  releaseDate(formatString: "MMM D, YYYY")
                  abv
                  ibu
                  sweetness
                  bodyMouthfeel
                  bitterness
                  fermentables
                  hops
                  webstorePrice
                  bcListingId
                  bcVariantId
                  currentRelease
                  image {
                    thumb: childImageSharp {
                      gatsbyImageData(
                        layout: FIXED
                        height: 80
                        width: 80
                        placeholder: BLURRED
                      )
                    }
                    src: childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                    }
                  }
                  gallery {
                    thumb: childImageSharp {
                      gatsbyImageData(
                        layout: FIXED
                        height: 80
                        width: 80
                        placeholder: BLURRED
                      )
                    }
                    src: childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                    }
                  }
                }
              }
              fields {
                slug
              }
            }
          }
        }
      }
    `,
  )
  return query.allMarkdownRemark.edges.map((edge) => {
    const { releaseDate } = edge.node.frontmatter.beerMeta
    if (!!releaseDate) {
      edge.node.frontmatter.beerMeta.releaseDate = moment(
        releaseDate,
        'MMM D, YYYY',
      )
    }
    return edge
  })
}

export default useBeerList
