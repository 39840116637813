import { graphql, useStaticQuery } from 'gatsby'

const useAwardImage = () => {
  const query = useStaticQuery(
    graphql`
      query useAwardImage {
        image: file(absolutePath: { regex: "/award-winning.png/" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
      }
    `,
  )
  query.image.alt = 'Award Winning Medal'
  return query.image
}

export default useAwardImage
