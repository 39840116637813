import { aaronSchema, companySchema, logoSchema } from './main'

const page = ({ headline, url, origin, description, image, sameAs }) => {
  const pageSchema = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    headline: headline,
    url: url,
    description: description,
    primaryImageOfPage: {
      '@type': 'ImageObject',
      url: image.url,
      width: image.width,
      height: image.height,
    },
    author: {
      '@id': '#schemaAaron',
    },
    copyrightHolder: {
      '@id': '#schemaOtherwiseBrewing',
    },
  }

  return JSON.stringify([
    companySchema({ url, image, origin, sameAs }),
    pageSchema,
    aaronSchema,
    logoSchema(origin),
  ])
}

export default page
