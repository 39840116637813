import { graphql, useStaticQuery } from 'gatsby'

const useNavStructure = () => {
  const query = useStaticQuery(
    graphql`
      query QueryNavStructure {
        site {
          siteMetadata {
            nav {
              label
              link
              options
            }
          }
        }
      }
    `,
  )
  return query.site.siteMetadata.nav
}

export default useNavStructure
