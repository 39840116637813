import { graphql, useStaticQuery } from 'gatsby'

const useMapsApiKey = () => {
  const query = useStaticQuery(
    graphql`
      query QueryMapsApiKey {
        site {
          siteMetadata {
            mapsApiKey
          }
        }
      }
    `,
  )
  return query.site.siteMetadata.mapsApiKey
}

export default useMapsApiKey
