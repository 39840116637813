import React, {
  createContext,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from 'react'
import { cloneDeep, isEqual } from 'lodash'
import {
  formatErrorMessage,
  handleError,
  is2xxStatus,
  isErrorObject,
  sanitizeFormValue,
} from '../utils'

import firebase from 'gatsby-plugin-firebase'
import owasp from 'owasp-password-strength-test'
import { useAuthState } from 'react-firebase-hooks/auth'

owasp.config({
  allowPassphrases: true,
  maxLength: 128,
  minLength: 8,
  minPhraseLength: 20,
  minOptionalTestsToPass: 2,
})

export const defaultUserInfo = {
  contact: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  },
  billing: {
    name: '',
    street: '',
    city: '',
    state: 'CA',
    zip: '',
  },
  shipping: {
    name: '',
    street: '',
    city: '',
    state: 'CA',
    zip: '',
  },
  ageVerification: false,
}

const validKeys = [
  ...new Set([
    ...Object.keys(defaultUserInfo.billing),
    ...Object.keys(defaultUserInfo.shipping),
    ...Object.keys(defaultUserInfo.contact),
  ]),
]

function formatUserInfo(data) {
  if (typeof data !== 'object') {
    return data
  }
  const clean = {}
  Object.keys(data).forEach((key) => {
    if (validKeys.includes(key)) {
      clean[key] = sanitizeFormValue(data[key], key)
    }
  })
  return clean
}

function _replaceValuesIfBlank(prev, curr) {
  const output = {}
  Object.keys(prev).forEach((key) => {
    if (typeof prev[key] === 'object' && prev[key] !== null) {
      output[key] = _replaceValuesIfBlank(prev[key], curr[key])
    } else {
      output[key] =
        !!curr[key] &&
        (prev[key] === null || prev[key] === undefined || prev[key] === '')
          ? sanitizeFormValue(curr[key], key)
          : prev[key]
    }
  })
  return output
}

function userInfoReducer(prev, action) {
  const info = cloneDeep(prev)
  switch (action.type) {
    case 'contact':
      info.contact = formatUserInfo(action.payload)
      return info
    case 'billing':
      info.billing = formatUserInfo(action.payload)
      return info
    case 'shipping':
      info.shipping = formatUserInfo(action.payload)
      return info
    case 'ageVerification':
      info.ageVerification = !!action.payload
      return info
    case 'reset':
      return cloneDeep(defaultUserInfo)
    case 'updateAll':
      return {
        contact: formatUserInfo(action.payload.contact),
        billing: formatUserInfo(action.payload.billing),
        shipping: formatUserInfo(action.payload.shipping),
        ageVerification: !!action.payload.ageVerification,
      }
    case 'replaceBlankValues':
      return _replaceValuesIfBlank(info, action.payload)
    default:
      throw new Error('Invalid action type provided')
  }
}

function ordersReducer(prev, action) {
  const orders = cloneDeep(prev)
  switch (action.type) {
    case 'add':
      orders.push(action.payload)
      return orders
    case 'remove':
      return orders.filter(
        (order) => action.payload.salesorder_id !== order.salesorder_id,
      )
    case 'addDetails':
      return orders.map((order) => {
        if (
          order.salesorder_id === action.payload.salesorder_id &&
          !!action.payload.line_items &&
          action.payload.line_items.length
        ) {
          order.details = action.payload
        }
        return order
      })
    case 'removeDetails':
      return orders.map((order) => {
        if (order.salesorder_id === action.payload.salesorder_id) {
          delete order.details
        }
        return order
      })
    case 'overwriteAll':
      return cloneDeep(action.payload)
    case 'reset':
      return []
    default:
      throw new Error()
  }
}

export const defaultPasswordState = {
  strength: 0,
  len: 0,
  errors: [],
  match: null,
  pass1: '',
  pass2: '',
}

export function passwordReducer(prevState, action) {
  const newState = cloneDeep(prevState)
  switch (action.type) {
    case 'password':
      const t = owasp.test(action.payload)
      let strength = action.payload.length < 8 ? action.payload.length : 8
      if (t.strong) {
        strength++
      }
      if (t.errors.length < 2) {
        strength++
      }
      newState.strength = strength
      newState.len = action.payload.length
      newState.match = null
      newState.errors = t.errors
      newState.pass1 = action.payload
      return newState
    case 'passwordConfirm':
      newState.pass2 = action.payload
      if (newState.strength < 10 || !action.payload.length) {
        newState.match = null
      } else if (!isEqual(newState.pass1, newState.pass2)) {
        newState.match = false
      } else {
        newState.match = true
      }
      return newState
    case 'clear':
      return defaultPasswordState
    default:
      throw new Error()
  }
}

async function _getIdToken(user) {
  if (!user) {
    return formatErrorMessage('you must be logged in to perform this action')
  }
  const idToken = await user
    .getIdToken(/* forceRefresh */ true)
    .catch(async (e) => {
      const resp = handleError(e)
      const data = await resp.json()
      return data
    })

  return isErrorObject(idToken)
    ? formatErrorMessage(idToken.error)
    : { idToken }
}

async function _getRemoteUserInfo(idToken) {
  const response = await fetch('/.netlify/functions/user', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${idToken}`,
    },
  }).catch(handleError)
  const data = await response.json()
  return isErrorObject(data) || !data.billing || !data.shipping || !data.contact
    ? formatErrorMessage('unable to fetch user data', data)
    : data
}

// get default User object's info if we have blank values in the remote
function _maybeAddUserFallbackData(data, user) {
  if (!data) {
    return {
      contact: {
        firstName: user && user.displayName ? user.displayName : '',
        email: user && user.email ? user.email : '',
        phone: user && user.phoneNumber ? user.phoneNumber : '',
      },
    }
  }
  if (!data.contact) {
    data.contact = {
      firstName: '',
      email: '',
      phone: '',
    }
  }
  if (!data.contact.firstName) {
    data.contact.firstName = user && user.displayName ? user.displayName : ''
  }
  if (!data.contact.email) {
    data.contact.email = user && user.email ? user.email : ''
  }
  if (!data.contact.phone) {
    data.contact.phone = user && user.phoneNumber ? user.phoneNumber : ''
  }

  return data
}

async function _setRemoteUserInfo(idToken, newUserInfo) {
  const response = await fetch('/.netlify/functions/user', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${idToken}`,
    },
    body: JSON.stringify(newUserInfo),
  }).catch(handleError)
  const data = await response.json()
  return is2xxStatus(response.status)
    ? data
    : formatErrorMessage('unable to update user data', data)
}

async function _getOrders(idToken, orders, forceRefresh) {
  // memoize
  if (!!orders && !!orders.length && !forceRefresh) return orders
  const response = await fetch(`/.netlify/functions/order`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${idToken}`,
    },
  }).catch(handleError)
  const data = await response.json()
  return is2xxStatus(response.status)
    ? data
    : formatErrorMessage('unable to fetch orders', data)
}

/**
 *
 * @param {string} idToken - required
 * @param {string} id - required
 * @param {array} orders - required
 * @param {bool} forceRefresh
 */

async function _getOrderDetails(idToken, id, orderDetails, forceRefresh) {
  // memoize
  if (!!orderDetails[id] && !forceRefresh) return orderDetails[id]
  const response = await fetch(`/.netlify/functions/order?id=${id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${idToken}`,
    },
  }).catch(handleError)
  const data = await response.json()
  return is2xxStatus(response.status)
    ? data
    : formatErrorMessage('unable to fetch order details', data)
}

async function _getPaymentMethods(idToken, paymentMethodsList, forceRefresh) {
  // memoize
  if (!!paymentMethodsList && !!paymentMethodsList.length && !forceRefresh) {
    return paymentMethodsList
  }
  const response = await fetch(`/.netlify/functions/stripe`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${idToken}`,
    },
  }).catch(handleError)
  const data = await response.json()
  return is2xxStatus(response.status)
    ? data
    : formatErrorMessage('unable to fetch payment methods', data)
}

async function _setPaymentMethod(idToken, paymentMethod) {
  const response = await fetch(`/.netlify/functions/stripe`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${idToken}`,
    },
    body: JSON.stringify(paymentMethod),
  }).catch(handleError)
  const data = await response.json()
  return is2xxStatus(response.status)
    ? data
    : formatErrorMessage('unable to set payment methods', data)
}

async function _updateStripeCustomer(idToken, customerObj) {
  const response = await fetch(`/.netlify/functions/stripe`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${idToken}`,
    },
    body: JSON.stringify(customerObj),
  }).catch(handleError)
  const data = await response.json()
  return is2xxStatus(response.status)
    ? data
    : formatErrorMessage('unable to update customer info', data)
}

async function _deletePaymentMethod(idToken, paymentMethod) {
  const response = await fetch(
    `/.netlify/functions/stripe?payment_method=${paymentMethod}`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${idToken}`,
      },
    },
  ).catch(handleError)
  const data = await response.json()
  return is2xxStatus(response.status)
    ? data
    : formatErrorMessage('unable to delete payment method', data)
}

async function _createUserBackend(user) {
  const { idToken, error } = await _getIdToken(user)
  if (error) return error

  const response = await fetch('/.netlify/functions/user', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${idToken}`,
    },
  }).catch(handleError)
  const data = await response.json()
  if (is2xxStatus(response.status)) {
    return data
  } else {
    await _deleteFirebaseUser(user)
    return formatErrorMessage(
      'Something went wrong in creating your acccount. Please try again.',
      data,
    )
  }
}

async function _sendEmailVerification(user) {
  const verify = await user.sendEmailVerification().catch(async (e) => {
    const resp = handleError(e)
    const data = await resp.json()
    return data
  })
  return isErrorObject(verify)
    ? formatErrorMessage('Unable to send verification email', verify)
    : {
        user,
        emailVerification:
          'Please check your email and click the link to verify your account.',
      }
}

async function _deleteFirebaseUser(user) {
  const db = firebase.firestore()
  await db.collection('users').doc(user.uid).delete()
  await user.delete()
}

export const UserContext = createContext()

export const UserProvider = ({ children }) => {
  const [user, loading, error] = useAuthState(firebase.auth())
  const [userInfo, dispatchUserInfo] = useReducer(
    userInfoReducer,
    defaultUserInfo,
  )
  const [orders, dispatchOrders] = useReducer(ordersReducer, [])
  const [orderDetails, setOrderDetails] = useState({})
  const [paymentMethodsList, setPaymentMethodsList] = useState(null)
  const [remoteUserInfoFetched, setRemoteUserInfoFetched] = useState(false)
  const [passwordState, dispatchPasswordState] = useReducer(
    passwordReducer,
    defaultPasswordState,
  )

  const getIdToken = useCallback(
    async (currentUser) => await _getIdToken(currentUser || user),
    [user],
  )

  /**
   * Attempt to get remote user info. Fail if not logged in.
   * Validate the data and replace what we are storing locally, if anything.
   */
  const getRemoteUserInfo = useCallback(async () => {
    const { idToken, error } = await getIdToken()
    if (error) return error
    const data = await _getRemoteUserInfo(idToken)
    return _maybeAddUserFallbackData(data, user)
  }, [user, getIdToken])

  /**
   * Attempt to get remote user info, once per login
   */
  const autoGetRemoteUserInfo = () => {
    if (!!user && !loading && !error) {
      setRemoteUserInfoFetched(false)
      getRemoteUserInfo().then((result) => {
        if (isErrorObject(result)) {
          console.error(error)
        } else {
          dispatchUserInfo({ type: 'updateAll', payload: result })
          setRemoteUserInfoFetched(true)
        }
      })
    }
  }
  useEffect(autoGetRemoteUserInfo, [user, loading, error, getRemoteUserInfo])

  /**
   * Push user data to Zoho
   * @param {object} newUserInfo - must add key of "target: []" with list of sections to update
   */
  const setRemoteUserInfo = useCallback(
    async (newUserInfo) => {
      const { idToken, error } = await getIdToken()
      if (error) return error
      const result = await _setRemoteUserInfo(idToken, newUserInfo)
      if (!isErrorObject(result)) {
        dispatchUserInfo({ type: 'updateAll', payload: newUserInfo })
      }
      return result
    },
    [getIdToken],
  )

  const getOrders = useCallback(
    async (forceRefresh) => {
      const { idToken, error } = await getIdToken()
      if (error) return error
      const result = await _getOrders(idToken, orders, forceRefresh)
      if (!isErrorObject(result)) {
        dispatchOrders({ type: 'overwriteAll', payload: result })
      }
      return result
    },
    [orders, getIdToken],
  )

  const getOrderDetails = useCallback(
    async (id, forceRefresh) => {
      const { idToken, error } = await getIdToken()
      if (error) return error
      const result = await _getOrderDetails(
        idToken,
        id,
        orderDetails,
        forceRefresh,
      )
      if (!isErrorObject(result) && !orderDetails[id]) {
        const details = cloneDeep(orderDetails)
        details[id] = result
        setOrderDetails(details)
      }
      return result
    },
    [orderDetails, getIdToken],
  )

  const getPaymentMethods = useCallback(
    async (forceRefresh) => {
      const { idToken, error } = await getIdToken()
      if (error) return error
      const result = await _getPaymentMethods(
        idToken,
        paymentMethodsList,
        forceRefresh,
      )
      if (!isErrorObject(result)) setPaymentMethodsList(result)
      return result
    },
    [paymentMethodsList, setPaymentMethodsList, getIdToken],
  )

  const setPaymentMethod = useCallback(
    async (paymentMethod) => {
      const { idToken, error } = await getIdToken()
      if (error) return error
      const result = await _setPaymentMethod(idToken, paymentMethod)
      return result
    },
    [getIdToken],
  )

  const updateStripeCustomer = useCallback(
    async (customerObj) => {
      const { idToken, error } = await getIdToken()
      if (error) return error
      const result = await _updateStripeCustomer(idToken, customerObj)
      return result
    },
    [getIdToken],
  )

  const deletePaymentMethod = useCallback(
    async (paymentMethod) => {
      const { idToken, error } = await getIdToken()
      if (error) return error
      const result = await _deletePaymentMethod(idToken, paymentMethod)
      return result
    },
    [getIdToken],
  )

  const createUserEmailPass = useCallback(async (email, password) => {
    // create firebase user
    const result = await firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .catch((e) => ({ error: e }))
    if (isErrorObject(result)) return result
    const user = firebase.auth().currentUser

    // create user backend
    const backend = await _createUserBackend(user)
    if (isErrorObject(backend)) return backend

    // send verification email to user
    const verify = await _sendEmailVerification(user)
    return verify
  }, [])

  const signInWithProvider = useCallback(async (name, isAccountCreation) => {
    let provider
    switch (name) {
      case 'facebook':
        provider = new firebase.auth.FacebookAuthProvider()
        break
      case 'google':
      default:
        provider = new firebase.auth.GoogleAuthProvider()
    }
    const result = await firebase
      .auth()
      .signInWithPopup(provider)
      .catch((e) => ({ error: e }))

    if (!isAccountCreation || isErrorObject(result)) {
      return result
    }

    // create user backend
    const user = firebase.auth().currentUser
    const backend = await _createUserBackend(user)
    return backend
  }, [])

  const resetPassword = useCallback(
    async (email) => {
      const resetEmail = email
        ? email
        : !!user && !!user.email
        ? user.email
        : null
      if (!resetEmail) {
        return formatErrorMessage('No email provided to reset')
      }
      const result = await firebase
        .auth()
        .sendPasswordResetEmail(resetEmail)
        .catch((e) => ({ error: e }))
      return isErrorObject(result)
        ? result
        : { message: 'Password reset email sent' }
    },
    [user],
  )

  const logout = useCallback(async () => {
    const result = await firebase
      .auth()
      .signOut()
      .catch((e) => ({ error: e }))
    dispatchUserInfo({ type: 'reset' })
    return result
  }, [])

  // logout triggers refresh of the user so we don't get stale state after email verification
  const loginEmailPass = useCallback(
    async (email, password) => {
      await logout()
      const result = await firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .catch((e) => ({ error: e }))
      if (isErrorObject(result)) return result
      const u = firebase.auth().currentUser
      if (!u || !u.emailVerified) {
        await logout()
        return formatErrorMessage(
          'You must verify your email before you can sign in',
        )
      }
      return result
    },
    [logout],
  )

  const reloadUser = useCallback(async (u) => {
    const user = u ? u : firebase.auth().currentUser
    if (user) {
      const reloaded = await user.reload()
      return reloaded
    }
  }, [])

  return (
    <UserContext.Provider
      value={{
        // new user actions
        passwordState,
        dispatchPasswordState,
        // firebase actions
        createUserEmailPass,
        loginEmailPass,
        signInWithProvider,
        resetPassword,
        logout,

        // firebase user
        user,
        loading,
        error,
        getIdToken,
        getRemoteUserInfo,
        remoteUserInfoFetched,
        reloadUser,
        // Zoho user data
        userInfo,
        dispatchUserInfo,
        defaultUserInfo, // for initializing local versions
        setRemoteUserInfo, // save local info to remote
        // Zoho order data
        dispatchOrders,
        getOrders,
        getOrderDetails,
        // Stripe actions
        getPaymentMethods,
        setPaymentMethod,
        updateStripeCustomer,
        deletePaymentMethod,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}
