import { cloneDeep } from 'lodash'
import moment from 'moment'

// const ZIP_CODES = {
//   sanFrancisco: [
//     '94101',
//     '94102',
//     '94103',
//     '94104',
//     '94105',
//     '94107',
//     '94108',
//     '94109',
//     '94110',
//     '94111',
//     '94112',
//     '94114',
//     '94115',
//     '94116',
//     '94117',
//     '94118',
//     '94119',
//     '94120',
//     '94121',
//     '94122',
//     '94123',
//     '94124',
//     '94125',
//     '94126',
//     '94127',
//     '94129',
//     '94130',
//     '94131',
//     '94132',
//     '94133',
//     '94134',
//     '94140',
//     '94141',
//     '94142',
//     '94146',
//     '94147',
//     '94157',
//     '94159',
//     '94164',
//     '94165',
//     '94166',
//     '94167',
//     '94168',
//     '94169',
//     '94170',
//     '94172',
//     '94188',
//   ],
// }

export function getStateFromZip(zipcode) {
  // Returns false on invalid zip-- else returns {code:"XX" long:"XXXXXXXXX"}

  // Ensure param is a string to prevent unpredictable parsing results
  if (typeof zipcode !== 'string') {
    console.log(
      'Must pass the zipcode as a string. -- Otherwise leading zeros could cause your zip code to be parsed outside base 10.',
    )
    return
  }

  // Ensure you don't parse codes that start with 0 as octal values
  zipcode = parseInt(zipcode, 10)

  // Code blocks alphabetized by state
  var states = [
    { min: 35000, max: 36999, code: 'AL', long: 'Alabama' },
    { min: 99500, max: 99999, code: 'AK', long: 'Alaska' },
    { min: 85000, max: 86999, code: 'AZ', long: 'Arizona' },
    { min: 71600, max: 72999, code: 'AR', long: 'Arkansas' },
    { min: 90000, max: 96699, code: 'CA', long: 'California' },
    { min: 80000, max: 81999, code: 'CO', long: 'Colorado' },
    { min: 6000, max: 6999, code: 'CT', long: 'Connecticut' },
    { min: 19700, max: 19999, code: 'DE', long: 'Deleware' },
    { min: 32000, max: 34999, code: 'FL', long: 'Florida' },
    { min: 30000, max: 31999, code: 'GA', long: 'Georgia' },
    { min: 96700, max: 96999, code: 'HI', long: 'Hawaii' },
    { min: 83200, max: 83999, code: 'ID', long: 'Idaho' },
    { min: 60000, max: 62999, code: 'IL', long: 'Illinois' },
    { min: 46000, max: 47999, code: 'IN', long: 'Indiana' },
    { min: 50000, max: 52999, code: 'IA', long: 'Iowa' },
    { min: 66000, max: 67999, code: 'KS', long: 'Kansas' },
    { min: 40000, max: 42999, code: 'KY', long: 'Kentucky' },
    { min: 70000, max: 71599, code: 'LA', long: 'Louisiana' },
    { min: 3900, max: 4999, code: 'ME', long: 'Maine' },
    { min: 20600, max: 21999, code: 'MD', long: 'Maryland' },
    { min: 1000, max: 2799, code: 'MA', long: 'Massachusetts' },
    { min: 48000, max: 49999, code: 'MI', long: 'Michigan' },
    { min: 55000, max: 56999, code: 'MN', long: 'Minnesota' },
    { min: 38600, max: 39999, code: 'MS', long: 'Mississippi' },
    { min: 63000, max: 65999, code: 'MO', long: 'Missouri' },
    { min: 59000, max: 59999, code: 'MT', long: 'Montana' },
    { min: 27000, max: 28999, code: 'NC', long: 'North Carolina' },
    { min: 58000, max: 58999, code: 'ND', long: 'North Dakota' },
    { min: 68000, max: 69999, code: 'NE', long: 'Nebraska' },
    { min: 88900, max: 89999, code: 'NV', long: 'Nevada' },
    { min: 3000, max: 3899, code: 'NH', long: 'New Hampshire' },
    { min: 7000, max: 8999, code: 'NJ', long: 'New Jersey' },
    { min: 87000, max: 88499, code: 'NM', long: 'New Mexico' },
    { min: 10000, max: 14999, code: 'NY', long: 'New York' },
    { min: 43000, max: 45999, code: 'OH', long: 'Ohio' },
    { min: 73000, max: 74999, code: 'OK', long: 'Oklahoma' },
    { min: 97000, max: 97999, code: 'OR', long: 'Oregon' },
    { min: 15000, max: 19699, code: 'PA', long: 'Pennsylvania' },
    { min: 300, max: 999, code: 'PR', long: 'Puerto Rico' },
    { min: 2800, max: 2999, code: 'RI', long: 'Rhode Island' },
    { min: 29000, max: 29999, code: 'SC', long: 'South Carolina' },
    { min: 57000, max: 57999, code: 'SD', long: 'South Dakota' },
    { min: 37000, max: 38599, code: 'TN', long: 'Tennessee' },
    { min: 75000, max: 79999, code: 'TX', long: 'Texas' },
    { min: 88500, max: 88599, code: 'TX', long: 'Texas' },
    { min: 84000, max: 84999, code: 'UT', long: 'Utah' },
    { min: 5000, max: 5999, code: 'VT', long: 'Vermont' },
    { min: 22000, max: 24699, code: 'VA', long: 'Virgina' },
    { min: 20000, max: 20599, code: 'DC', long: 'Washington DC' },
    { min: 98000, max: 99499, code: 'WA', long: 'Washington' },
    { min: 24700, max: 26999, code: 'WV', long: 'West Virginia' },
    { min: 53000, max: 54999, code: 'WI', long: 'Wisconsin' },
    { min: 82000, max: 83199, code: 'WY', long: 'Wyoming' },
  ]

  var state = states.filter(function (s) {
    return s.min <= zipcode && s.max >= zipcode
  })

  if (state.length === 0) {
    return false
  } else if (state.length > 1) {
    console.error('Whoops found two states')
  }
  return { code: state[0].code, long: state[0].long }
}

export function tryParseJson(str) {
  try {
    return JSON.parse(str)
  } catch (e) {
    return null
  }
}

export const formatPhone = (original) => {
  let num = original.replace(/\D/g, '')
  if (num.substring(0, 1) === '1') {
    num = num.slice(1)
  }
  if (num.length <= 3) {
    return num
  } else if (num.length > 3 && num.length <= 6) {
    return num.substring(0, 3) + '-' + num.substring(3, 6)
  } else {
    return (
      num.substring(0, 3) +
      '-' +
      num.substring(3, 6) +
      '-' +
      num.substring(6, 10)
    )
  }
}

export function formatMailchimpValues(values, slug, interests) {
  const nameParts = values.name.split(' ')
  const FNAME = nameParts.shift()
  const LNAME = nameParts.length ? nameParts.join(' ') : ' '
  const mergeFields = {
    FNAME,
    LNAME,
    PATHNAME: slug,
  }
  return {
    email: values.email,
    mergeFields,
    interests,
  }
}

export function isValidRGBA(input) {
  const regex = new RegExp(
    /rgba\(\s*[0-9]{1,3},\s*[0-9]{1,3},\s*[0-9]{1,3},\s*[0-1]{1}(\.[0-9]+)?\s*\)/g,
  )
  if (!input || typeof input !== 'string') return false
  const result = input.match(regex)
  return result !== null && Array.isArray(result) && !!result.length
}

export function formatTelLink(phone) {
  let newPhone = phone.replace(/\D/g, '').toString()
  if (newPhone.substring(0, 1) !== '1') {
    newPhone = '1' + newPhone
  }
  return 'tel:+' + newPhone
}

export function isErrorObject(result) {
  return (
    typeof result === 'object' &&
    result !== null &&
    (!!result.error || !!result.error_message)
  )
}

export function handleError(error) {
  console.error(error)
  const body =
    typeof error === 'string'
      ? JSON.stringify({ error })
      : JSON.stringify({
          error: error.message ? error.message : 'Unknown error',
        })
  const init = { status: 500 }
  return new Response(body, init)
}

export function is2xxStatus(status) {
  const s = !!status ? parseInt(status) : 0
  return s >= 200 && s < 300
}

export function formatErrorMessage(msg, data) {
  return {
    error: {
      message: `Error: ${msg}`,
      data,
    },
  }
}

export function titleCase(text) {
  return typeof text === 'string' && text.length
    ? text
        .trim()
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
    : text
}

/**
 * Utility functions to help format user info before submission
 * @param {Object} newUserInfo
 * @param {Func} setFunc
 */

export function sanitizeFormValue(value, field) {
  if (typeof value !== 'string') return ''

  if (field.toLowerCase().indexOf('zip') !== -1) {
    return value.replace(/\D/g, '').substring(0, 5)
  }

  if (field.toLowerCase().indexOf('phone') !== -1) {
    return formatPhone(value)
  }

  return value.replace(/(<([^>]+)>)/gi, '')
}

export function handleContactInfoChange(newUserInfo, field, value) {
  const cleanValue = sanitizeFormValue(value, field)
  newUserInfo.contact[field] = cleanValue
  if (
    !newUserInfo.contact.lastName &&
    newUserInfo.contact.firstName.indexOf(' ') !== -1
  ) {
    const [firstName, ...lastNames] = newUserInfo.contact.firstName.split(' ')
    newUserInfo.contact.firstName = firstName
    newUserInfo.contact.lastName = lastNames.join(' ')
  }
  return newUserInfo
}

export function getErrorMessage(response) {
  console.error(response)
  if (typeof response !== 'object' || response === null) {
    return response
  }
  const { error, message, data } = response
  if (
    !!error &&
    !!error.data &&
    !!error.data.error &&
    typeof error.data.error === 'string'
  ) {
    return error.data.error
  }
  if (!!data && !!data.error && typeof data.error === 'string') {
    return data.error
  }
  if (!!error && typeof error === 'string') {
    return error
  }
  if (!!error && !!error.error && typeof error.error === 'string') {
    return error.error
  }
  if (!!error && !!error.message && typeof error.message === 'string') {
    return error.message
  }
  if (!error && !!message && typeof message === 'string') {
    return message
  }

  if (
    !!data &&
    !!data.error &&
    !!data.error.message &&
    typeof data.error.message === 'string'
  ) {
    return data.error.message
  }
  return 'Unknown error'
}

/**
 * Assumes @name is formatted as {sectionName}_{fieldName}
 */
export function handleUserAddressChange(
  newUserInfo,
  isSameShipping,
  name,
  value,
) {
  const [section1, field] = name.split('_')
  const section2 = section1 === 'billing' ? 'shipping' : 'billing'
  const cleanValue = sanitizeFormValue(value, field)
  newUserInfo[section1][field] = cleanValue
  if (
    // copy values if they're the same
    isSameShipping &&
    Object.keys(newUserInfo[section2]).includes(field)
  ) {
    newUserInfo[section2][field] = cleanValue
  }
  // do not allow out-of-state shipping address
  newUserInfo.shipping.state = 'CA'
  if (!newUserInfo.billing.state) {
    newUserInfo.billing.state = 'CA'
  }
  return newUserInfo
}

export function findIfIsSameShipping(shipping, billing) {
  let result = true
  let keys = Object.keys(shipping)
  for (let i = 0; i < keys.length; i++) {
    if (shipping[keys[i]] === billing[keys[i]]) {
      continue
    } else {
      result = false
      break
    }
  }
  return result
}

export function findUrl(obj) {
  let result = null
  if (typeof obj === 'string' && obj.indexOf('https://') === 0) {
    return obj
  }
  if (typeof obj === 'object' && obj !== null) {
    const keys = Object.keys(obj)
    for (let i = 0; i < keys.length; i++) {
      result = findUrl(obj[keys[i]])
      if (result) {
        break
      }
    }
  }
  return result
}

export function removeTrailingSlash(path) {
  if (path === '/') {
    return path
  }
  return path.slice(-1) === '/' ? path.slice(0, -1) : path
}

export function getValidDates(date, gitAuthorTime, gitCreatedTime) {
  const mDate =
    !!date && typeof date === 'string' && date.replace(/\D/g, '').length
      ? moment(date, 'MMM D, YYYY')
      : null
  const mCreate = moment(gitCreatedTime)
  const mModified =
    !!gitAuthorTime && gitAuthorTime !== 'Invalid Date'
      ? moment(gitAuthorTime)
      : null

  const output = {
    date: !!mDate && mDate.isValid() ? mDate : mCreate,
  }
  output.dateModified =
    mModified && mModified.isValid() ? mModified : output.date
  return output
}

export function centsToDecimals(cents) {
  const c = cents.toString()
  const decimal =
    c.length === 1
      ? `0.0${c}`
      : [c.slice(0, c.length - 2), c.slice(c.length - 2)].join('.')
  return parseFloat(decimal)
}

export function round2Decimals(number) {
  return Math.round((parseFloat(number) + Number.EPSILON) * 100) / 100
}

export function findCaseSize(description) {
  if (!description) return null
  const numerals = description.match(/\d+/)
  return !numerals || 24 % numerals[0] !== 0 ? null : 24 / numerals[0]
}

/**
 *
 * @param {object} number string string number
 */

export function calculateItemPrice({
  price,
  description,
  availability,
  quantity,
}) {
  const floatPrice = round2Decimals(price)
  const isPreorder = (availability || '').toLowerCase() === 'preorder'
  const caseSize = findCaseSize(description)
  const basePrice = round2Decimals(isPreorder ? floatPrice - 1 : floatPrice)
  const casePrice = round2Decimals(basePrice * 0.9)
  const isCase = caseSize && quantity >= caseSize
  return {
    isCase,
    isPreorder,
    price: isCase ? casePrice : basePrice,
    listPrice: floatPrice,
  }
}

/**
 *
 * @param {number} subtotal
 * @param {object|undefined} code - {code, status, msg, amount, expires: {seconds, nanoseconds}, type}
 * @returns {number} amount of discount that applies to the sale
 */

export function maybeApplyDiscountCode(subtotal, discountData) {
  if (!discountData || (!!discountData && discountData.status !== 'found')) {
    return 0
  }
  const { type, amount } = discountData
  let discount
  switch (type) {
    case 'percent':
      discount = round2Decimals(subtotal * (amount / 100))
      break
    case 'dollar':
      discount = amount
      break
    default:
      discount = 0
  }
  return discount
}

/**
 *
 * @param {array} cartItems
 * @param {bool} isLocalPickup
 * @param {object} shipping
 */

export function calculateCharges(
  cartItems,
  isLocalPickup,
  shipping,
  discountData,
) {
  function findPrice(item) {
    return calculateItemPrice({ ...item, price: parseFloat(item.price) }).price
  }

  function findShippingCharge(isLocalPickup, subtotal /*zip*/) {
    if (isLocalPickup) return 0
    if (subtotal >= 100) return 0
    return 15
    // return !!zip
    //   ? ZIP_CODES.sanFrancisco.includes(zip.toString())
    //     ? 10
    //     : 15
    //   : 15
  }
  const charges = {
    listPrice: 0,
    adjustedPrice: 0, // for preorders, case discounts
    discountAmount: 0,
    subtotal: 0,
    shipping: 0,
    salesTax: 0,
    grandTotal: 0,
    itemCount: 0,
  }
  charges.itemCount =
    !!cartItems && !!cartItems.length
      ? cartItems
          .map((item) => parseInt(item.quantity))
          .reduce((prev, cur) => prev + cur)
      : 0
  charges.listPrice =
    !!cartItems && !!cartItems.length
      ? cartItems
          .map((item) => parseFloat(item.price) * parseFloat(item.quantity))
          .reduce((prev, cur) => prev + cur)
      : 0
  charges.adjustedPrice =
    !!cartItems && !!cartItems.length
      ? cartItems
          .map((item) => findPrice(item) * parseFloat(item.quantity))
          .reduce((prev, cur) => prev + cur)
      : 0
  charges.discountAmount = maybeApplyDiscountCode(
    charges.adjustedPrice,
    discountData,
  )
  charges.subtotal = charges.adjustedPrice - charges.discountAmount
  charges.shipping =
    charges.subtotal > 0
      ? findShippingCharge(isLocalPickup, charges.subtotal, shipping.zip)
      : 0
  charges.salesTax = round2Decimals(charges.subtotal * 0.08625)
  charges.grandTotal = charges.subtotal + charges.shipping + charges.salesTax
  return charges
}

export function timeoutPromise(ms, promise, message) {
  return new Promise((resolve, reject) => {
    const timeoutId = setTimeout(() => {
      reject(new Error(message))
    }, ms)
    promise
      .then(
        (res) => {
          clearTimeout(timeoutId)
          resolve(res)
        },
        (err) => {
          clearTimeout(timeoutId)
          reject(err)
        },
      )
      .catch(handleError)
  })
}

export function calculateItemDiscounts(
  price,
  description,
  availability,
  quantity,
) {
  const priceData = calculateItemPrice({
    price,
    description,
    availability,
    quantity,
  })
  const caseSize = findCaseSize(description)
  const formattedPrice = priceData.price.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  })
  const totalCost = (priceData.price * quantity).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  })
  return { caseSize, formattedPrice, totalCost }
}

export function cartItemToGA4Item(input) {
  if (typeof input !== 'object' || input === null) {
    return
  }
  const item = cloneDeep(input)
  if (!item.quantity) {
    item.quantity = 1
  }
  const { sku, name, quantity } = item
  const { price, listPrice } = calculateItemPrice(item)
  const output = {
    item_id: sku,
    item_name: name,
    price: listPrice,
    currency: 'USD',
    quantity,
  }
  if (price < listPrice) {
    output.discount = listPrice - price
  }
  return output
}
export function cartItemsToRedditItems(items) {
  const output = { itemCount: 0, value: 0, products: [] }
  if (typeof items !== 'object' || items === null) {
    return output
  }
  output.itemCount = items.reduce(
    (total, item) => (total + !!item.quantity ? item.quantity : 1),
    0,
  )
  output.value = items.reduce(
    (total, item) => total + (!!item.quantity ? item.quantity : 1) * item.price,
    0,
  )
  output.products = items.map((item) => ({ id: item.sku, name: item.name }))
  return output
}

/**
 *
 * @param {string} label
 * @param {object|array} data
 * @param {bool} dataIsItem
 */
export function trackingEvent(label, data, dataIsItem) {
  // GA4 label format is `add_to_cart` vs. Reddit `AddToCart`
  const rdtLabel = label
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join('')
  // do console log if no tags
  const gtag =
    typeof window === 'undefined' || !window.gtag
      ? (event, label, data) => {
          console.log({ type: 'GA4', event, label, data })
        }
      : window.gtag
  const rdt =
    typeof window === 'undefined' || !window.rdt
      ? (event, label, data) => {
          console.log(['RDT', 'track', label, data])
        }
      : window.rdt
  const fbq =
    typeof window === 'undefined' || !window.fbq
      ? (event, label, data) => {
          console.log(['FBQ', 'track', label, data])
        }
      : window.fbq
  // calculate cart items if needed, accepts either a single item or an array
  if (dataIsItem) {
    const gaItems = Array.isArray(data)
      ? data.map((item) => cartItemToGA4Item(item))
      : [cartItemToGA4Item(data)]
    const rdtItems = Array.isArray(data)
      ? cartItemsToRedditItems(data)
      : cartItemsToRedditItems([data])
    if (gaItems && gaItems.length) {
      gtag('event', label, {
        currency: 'USD',
        items: gaItems,
      })
      rdt('track', rdtLabel, {
        currency: 'USD',
        itemCount: rdtItems.itemCount,
        value: rdtItems.value,
        products: rdtItems.products,
      })
      fbq('track', rdtLabel)
    }
  } else {
    gtag('event', label, data)
    rdt('track', rdtLabel)
    fbq('track', rdtLabel)
  }
}

export async function gtagGetUserIds() {
  let clientId = undefined
  let sessionId = undefined
  let gclid = undefined
  const gtag =
    typeof window === 'undefined' || !window.gtag
      ? (event, label, data, callback) => {
          console.log({ event, label, data, callback })
        }
      : window.gtag
  clientId = await new Promise((resolve) => {
    gtag('get', 'G-7QDGJLWJT0', 'client_id', resolve)
  })
  sessionId = await new Promise((resolve) => {
    gtag('get', 'G-7QDGJLWJT0', 'client_id', resolve)
  })
  gclid = await new Promise((resolve) => {
    gtag('get', 'G-7QDGJLWJT0', 'gclid', resolve)
  })
  return { clientId, sessionId, gclid }
}

export function validateDiscountCode(code, doc) {
  if (!doc || !doc.exists) {
    return { code, status: 'notfound', msg: 'Discount code not found.' }
  }
  const { expires } = doc.data()

  return expires.seconds > Date.now() / 1000
    ? {
        code,
        status: 'found',
        msg: 'Success! Discount code applied.',
        ...doc.data(),
      }
    : {
        code,
        status: 'expired',
        msg: 'Sorry, that code has expired.',
        ...doc.data(),
      }
}

// convert to/from string to avoid floating point error
export function priceToCents(price) {
  const numParts = round2Decimals(price).toString().split('.')
  // add cents if neccessary
  if (numParts.length === 1) {
    numParts.push('00')
  } else if (numParts[1].length === 1) {
    numParts[1] = `${numParts[1]}0`
  }
  return parseInt(numParts.join(''))
}

/**
 * Generate an excerpt from the text of the article, in Wordpress style
 * @param {string} description: long html text string
 * @param {string} slug: optional link for "read more"
 * @returns html string with optional link
 */

export function generateExcerpt(description, slug) {
  const excerpt = (description || '').split('<!--read more-->').shift().trim()
  if (!excerpt) return
  const matches = [...excerpt.matchAll(/<\/[^>]+>/g)]
  if (!matches || !matches.length) return
  const closingTag = matches.pop()[0]
  const parts = excerpt.split(closingTag).filter((x) => x)
  const link = !!slug
    ? ` <a href='${slug}' class='read-more'>Read more &raquo;</a>`
    : ''

  parts[parts.length - 1] = `${parts[parts.length - 1]}${link}${closingTag}`
  return parts.join(closingTag)
}

export function isCaZipCode(zipcode) {
  const num = parseInt(zipcode) || 0
  return num > 90000 && num < 97000
}

export function reloadAccelPay() {
  console.log('reloadAccelPay')
  if (
    !!window &&
    !!window.apbrand &&
    !!window.apbrand.accelpay &&
    !!window.apbrand.accelpay.setup &&
    !window.apbrandLoading &&
    !!document.querySelector('[data-bclistingid]') // only if buttons
  ) {
    window.apbrandLoading = true
    window.apbrand.accelpay.setup().then(() => {
      window.apbrandLoading = false
    })
  }
}

// non-cryptographic hash, for keys
// https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript/52171480#52171480
export function cyrb53(str, seed = 0) {
  let h1 = 0xdeadbeef ^ seed,
    h2 = 0x41c6ce57 ^ seed
  for (let i = 0, ch; i < str.length; i++) {
    ch = str.charCodeAt(i)
    h1 = Math.imul(h1 ^ ch, 2654435761)
    h2 = Math.imul(h2 ^ ch, 1597334677)
  }
  h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507)
  h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909)
  h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507)
  h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909)

  return 4294967296 * (2097151 & h2) + (h1 >>> 0)
}
