import { graphql, useStaticQuery } from 'gatsby'

const useGitCurrentCommit = () => {
  const query = useStaticQuery(
    graphql`
      query QueryGitCurrentCommit {
        allGitBranch(filter: { current: { eq: true } }) {
          edges {
            node {
              commit
              current
              id
              name
            }
          }
        }
      }
    `,
  )
  return query.allGitBranch.edges[0].node
}

export default useGitCurrentCommit
