import React, {
  createContext,
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react'
import {
  validateDiscountCode as _validateDiscountCode,
  isErrorObject,
} from '../utils'

import { cloneDeep } from 'lodash'
import firebase from 'gatsby-plugin-firebase'
import { useGitCurrentCommit, useLiquidRailsInventory } from '../hooks'

export const defaultCartContext = {
  version: null,
  expireOnNextSession: false,
  cartItems: [],
}

const defaultInventory = []

function cartReducer(prev, action) {
  const cartItems = cloneDeep(prev)
  switch (action.type) {
    case 'increment':
      return cartItems.map((item) => {
        if (item.sku === action.payload.sku) {
          item.quantity = item.quantity + 1
          item.selected = true
        }
        return item
      })
    case 'decrement':
      return cartItems.map((item) => {
        if (item.sku === action.payload.sku) {
          item.quantity = item.quantity > 0 ? item.quantity - 1 : 0
        }
        return item
      })
    case 'delete':
      return cartItems.map((item) => {
        if (item.sku === action.payload.sku) {
          item.quantity = 0
          item.selected = false
        }
        return item
      })
    case 'clearAll':
      return cartItems.map((item) => {
        item.quantity = 0
        item.selected = false
        return item
      })
    case 'resetToLocalStorage':
      return cloneDeep(action.payload)
    case 'refreshInventory':
      return action.payload.map((item) => {
        const preexisting = cartItems.filter(({ sku }) => sku === item.sku)[0]
        item.quantity = !!preexisting
          ? preexisting.quantity < item.stock
            ? preexisting.quantity || 0
            : item.stock || 0
          : 0
        item.selected = !!preexisting ? !!preexisting.selected : false
        return item
      })
    default:
      throw new Error()
  }
}

function _getCartFromLocalStorage(git) {
  let ls
  try {
    ls = JSON.parse(localStorage.getItem('shoppingCart'))
  } catch (e) {
    ls = null
  }
  if (
    ls &&
    ls.cartItems &&
    ls.cartItems.length &&
    ls.version === git.commit &&
    !ls.expireOnNextSession
  ) {
    return ls
  }
  const newCart = cloneDeep(defaultCartContext)
  newCart.version = git.commit
  return newCart
}

// temporary fix until API can be re-established
async function _fetchInventory() {
  // const response = await fetch('/.netlify/functions/inventory', {
  //   method: 'GET',
  // }).catch(handleError)
  // const data = await response.json()
  // return data
  return useLiquidRailsInventory()
}

function _countCartItems(cartItems) {
  const count =
    !!cartItems && !!cartItems.length
      ? cartItems.map((item) => item.quantity).reduce((prev, cur) => prev + cur)
      : 0
  const selected =
    !!cartItems && !!cartItems.length
      ? cartItems.filter(({ selected }) => selected).length
      : 0
  return { count, selected }
}

async function _fetchDiscountData(code) {
  const db = firebase.firestore()
  const collection =
    typeof window === 'undefined' ||
    (!!window && !window.location) ||
    (!!window && window.location.host !== 'www.otherwisebrewing.com')
      ? 'dev-discounts'
      : 'discounts'
  return await db
    .collection(collection)
    .doc(code.toLowerCase())
    .get()
    .catch((e) => ({ error: e }))
}

export const ShoppingCartContext = createContext(defaultCartContext)

export const ShoppingCartProvider = ({ children }) => {
  const fetchedLocalStorage = useRef(false)
  const git = useGitCurrentCommit()
  const [checkoutStep, setCheckoutStep] = useState(null) // null, started, processing, success, error
  const [checkoutState, setCheckoutState] = useState(0) // used for the cart swimlanes
  const [cartCount, setCartCount] = useState(0)
  const [discountCode, setDiscountCode] = useState('')
  const [discountData, setDiscountData] = useState({})
  const [cartSelectedItemsCount, setCartSelectedItemsCount] = useState(0)
  const [crossDomainGLCode, setCrossDomainGLCode] = useState(undefined)
  const [inventory, setInventory] = useState(defaultInventory)
  const [expireOnNextSession, setExpireOnNextSession] = useState(false)
  const [cartItems, dispatchCartItems] = useReducer(cartReducer, [])
  const [accelPayLoaded, setAccelPayLoaded] = useState(false)

  const fetchInventory = useCallback(async () => {
    const data = await _fetchInventory()
    if (!isErrorObject(data)) {
      setInventory(data)
    }
  }, [setInventory])

  // get cartItems from localStorage on init and populate store
  useEffect(() => {
    if (!fetchedLocalStorage.current) {
      const ls = _getCartFromLocalStorage(git)
      dispatchCartItems({
        type: 'resetToLocalStorage',
        payload: ls.cartItems,
      })
      fetchedLocalStorage.current = true
    }
  }, [git])

  // record cart data in localStorage for return sessions
  useEffect(() => {
    if (!!cartItems && cartItems.length) {
      localStorage.setItem(
        'shoppingCart',
        JSON.stringify({
          version: git.commit,
          expireOnNextSession: false,
          cartItems,
        }),
      )
    }
  }, [git.commit, cartItems])

  // fetch Zoho data, but not more than once an hour
  useEffect(() => {
    if (!fetchedLocalStorage.current) return
    fetchInventory()
    const refresh = setInterval(fetchInventory, 3600000)
    return () => {
      clearInterval(refresh)
    }
  }, [fetchInventory])

  // refresh cart when new inventory fetched
  useEffect(() => {
    if (!!inventory && inventory.length) {
      dispatchCartItems({
        type: 'refreshInventory',
        payload: inventory,
      })
    }
  }, [inventory])

  // count items in cart
  useEffect(() => {
    const { count, selected } = _countCartItems(cartItems)
    setCartCount(count)
    setCartSelectedItemsCount(selected)
  }, [cartItems])

  // set cart to expire on next session (for checkout redirect)
  useEffect(() => {
    if (!!expireOnNextSession) {
      localStorage.setItem(
        'shoppingCart',
        JSON.stringify({
          version: git.commit,
          expireOnNextSession: true,
          cartItems,
        }),
      )
    }
  }, [git.commit, cartItems, expireOnNextSession])

  const validateDiscountCode = useCallback(
    async (code) => {
      const doc = await _fetchDiscountData(code)
      if (isErrorObject(doc)) return doc
      const validated = _validateDiscountCode(code, doc)
      setDiscountCode(code)
      setDiscountData(validated)
      return validated
    },
    [setDiscountCode],
  )

  return (
    <ShoppingCartContext.Provider
      value={{
        cartItems,
        dispatchCartItems,
        inventory,
        cartCount,
        cartSelectedItemsCount,
        fetchInventory,
        checkoutStep,
        setCheckoutStep,
        checkoutState,
        setCheckoutState,
        discountCode,
        discountData,
        validateDiscountCode,
        setExpireOnNextSession,
        crossDomainGLCode,
        setCrossDomainGLCode,
        accelPayLoaded,
        setAccelPayLoaded,
      }}
    >
      {children}
    </ShoppingCartContext.Provider>
  )
}
