import { graphql, useStaticQuery } from 'gatsby'

const useDefaultImage = () => {
  const query = useStaticQuery(
    graphql`
      query DefaultImageQuery {
        src: file(absolutePath: { regex: "/default-photo.jpg/" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            original {
              width
              height
            }
          }
        }
      }
    `,
  )
  return { src: query.src, alt: 'Cofounders Aaron Gervais and Stellar Cassidy' }
}

export default useDefaultImage
