import { graphql, useStaticQuery } from 'gatsby'

const useCheersImage = () => {
  const query = useStaticQuery(
    graphql`
      query CheersImageQuery {
        image: file(
          absolutePath: {
            regex: "/alcoholic-beverage-bar-beer-beverage-1269025.jpg/"
          }
        ) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
      }
    `,
  )
  query.image.alt = 'A group of friends cheersing beer'
  return query.image
}

export default useCheersImage
