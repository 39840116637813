const name = 'Otherwise Brewing'
const legalName = 'Otherwise Brewing LLC'
const alternateName = [
  'OtherwiseBrewing',
  'Otherwise Brewing Co.',
  'Otherwing Brewing Company',
  'Otherwise Beer',
  'Otherwise Brewery',
  'OWB Holdings',
]
const address = {
  '@type': 'PostalAddress',
  streetAddress: '1402 Grant Ave',
  addressLocality: 'San Francisco',
  addressRegion: 'Calfornia',
  postalCode: 94133,
  addressCountry: 'United States',
}
const geo = {
  '@type': 'GeoCoordinates',
  latitude: '37.7999026',
  longitude: '-122.4121835',
}
const hasMap =
  'https://www.google.com/maps/place/Otherwise+Brewing/@37.7999027,-122.407318,17z/data=!3m1!4b1!4m6!3m5!1s0x808f7fe8df5fdd9f:0xe69d483c382c3ad8!8m2!3d37.7999027!4d-122.407318!16s%2Fg%2F11p3hg4qyl?entry=ttu'
const email = 'info@otherwisebrewing.com'
const logo = {
  '@id': '#schemaLogo',
}
const employee = '#schemaAaron'
const telephone = '+1-415-300-3474'
const contactPoint = {
  '@type': 'ContactPoint',
  contactType: 'customer service',
  email: 'info@otherwisebrewing.com',
  availableLanguage: ['English'],
  telephone,
}
const openingHours = [
  'We-Fr 16:00:00-23:59:59',
  'Sa 12:00:00-23:59:59',
  'Su-Tu 16:00:00-22:00:00',
]

export const aaronSchema = {
  '@context': 'http://schema.org',
  '@type': 'Person',
  '@id': '#schemaAaron',
  familyName: 'Gervais',
  givenName: 'Aaron',
  name: 'Aaron Gervais',
  jobTitle: ['Chief Executive Officer'],
  worksFor: '#schemaOtherwiseBrewing',
}

export const logoSchema = (origin) => ({
  '@context': 'http://schema.org/',
  '@type': 'ImageObject',
  '@id': '#schemaLogo',
  url: origin + '/core/otherwise-logo.png',
  height: 294,
  width: 780,
})

export const buildImage = (image) => ({
  '@context': 'http://schema.org/',
  '@type': 'ImageObject',
  url: image.url,
  height: image.height,
  width: image.width,
})

export const companySchema = ({ url, image, origin, sameAs }) => ({
  '@context': 'http://schema.org/',
  '@type': 'Brewery',
  '@id': '#schemaOtherwiseBrewing',
  mainEntityOfPage: {
    '@type': 'WebPage',
    '@id': url,
  },
  name,
  legalName,
  openingHours,
  employee,
  alternateName,
  url: origin,
  logo,
  address,
  geo,
  contactPoint,
  telephone,
  email,
  hasMap,
  sameAs: sameAs || [],
  image: buildImage(image),
})

export const publisherSchema = ({ image, origin, sameAs }) => ({
  '@context': 'http://schema.org/',
  '@type': 'Organization',
  '@id': '#schemaOtherwiseBrewing',
  name,
  legalName,
  employee,
  alternateName,
  url: origin,
  logo,
  address,
  contactPoint,
  email,
  sameAs: sameAs || [],
  image: buildImage(image),
})
