// temporary solution until inventory API can be re-developed without Zoho

const useLiquidRailsInventory = () => [
  {
    name: '30,000 Feet',
    description: '4-pack of 16-oz cans',
    taxable: true,
    price: 18.99,
    sku: 'owb_30kfeet_4x16oz',
    item_id: '2396198000000469428',
    status: 'active',
    stock: 999,
    availability: 'Available',
    expectedRelease: undefined,
    bcListingId: 1207713,
    bcVariantId: 1207715,
  },
  {
    name: 'Calrose Crisp',
    description: '4-pack of 16-oz cans',
    taxable: true,
    price: 14.99,
    sku: 'owb_calrosecrisp_4x16oz',
    item_id: '2396198000000071536',
    status: 'active',
    stock: 999,
    availability: 'Available',
    expectedRelease: 'October 12, 2020',
    bcListingId: 1159614,
    bcVariantId: 1159615,
  },
  {
    name: 'Guava Fresca',
    description: '4-pack of 16-oz cans',
    taxable: true,
    price: 18.99,
    sku: 'owb_guavafresca_4x16oz',
    item_id: '2396198000000469366',
    status: 'active',
    stock: 999,
    availability: 'Available',
    expectedRelease: undefined,
    bcListingId: 1159620,
    bcVariantId: 1159621,
  },
  {
    name: 'Hella Nibs',
    description: '4-pack of 16-oz cans',
    taxable: true,
    price: 20.99,
    sku: 'owb_hellanibs_4x16oz',
    item_id: '2396198000000414003',
    status: 'active',
    stock: 999,
    availability: 'Available',
    expectedRelease: 'October 12, 2020',
    bcListingId: 1159622,
    bcVariantId: 1159623,
  },
  {
    name: 'IFLS',
    description: '4-pack of 16-oz cans',
    taxable: true,
    price: 18.99,
    sku: 'owb_ifls_4x16oz',
    item_id: '2396198000000469249',
    status: 'active',
    stock: 999,
    availability: 'Available',
    expectedRelease: 'November 1, 2023',
    bcListingId: 1159618,
    bcVariantId: 1159619,
  },
  {
    name: 'Meringue & Tang',
    description: '4-pack of 16-oz cans',
    taxable: true,
    price: 18.99,
    sku: 'owb_meringuetang_4x16oz',
    item_id: '2396198000000469486',
    status: 'active',
    stock: 0,
    availability: 'Not Available',
    expectedRelease: undefined,
  },
  {
    name: 'Péché de Pêche',
    description: '4-pack of 16-oz cans',
    taxable: true,
    price: 18.99,
    sku: 'owb_pechedepeche_4x16oz',
    item_id: '2396198000000414030',
    status: 'active',
    stock: 0,
    availability: 'Not Available',
    expectedRelease: 'October 12, 2020',
  },
  {
    name: 'Sonic Bloom',
    description: '4-pack of 16-oz cans',
    taxable: true,
    price: 18.99,
    sku: 'owb_sonicbloom_4x16oz',
    item_id: '2396198000000071518',
    status: 'active',
    stock: 999,
    availability: 'Available',
    expectedRelease: 'January 28, 2021',
    bcListingId: 1213524,
    bcVariantId: 1213526,
  },
  {
    name: 'Western Addition',
    description: '4-pack of 16-oz cans',
    taxable: true,
    price: 15.99,
    sku: 'owb_westernaddition_4x16oz',
    item_id: '2396198000000414073',
    status: 'active',
    stock: 999,
    availability: 'Available',
    expectedRelease: 'October 12, 2020',
    bcListingId: 1159616,
    bcVariantId: 1159617,
  },
  {
    name: 'St-Sureau',
    description: '4-pack of 16-oz cans',
    taxable: true,
    price: 18.99,
    sku: 'owb_stsureau_4x16oz',
    item_id: 'owb_stsureau_4x16oz',
    status: 'active',
    stock: 999,
    availability: 'Available',
    expectedRelease: 'June 12, 2024',
    bcListingId: 1442586,
    bcVariantId: 1442588,
  },
]

export default useLiquidRailsInventory
