import 'firebase/auth'
import 'firebase/firestore'
import './src/style/all.sass'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/300-italic.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/400-italic.css'
import '@fontsource/roboto/700.css'
import '@fontsource/roboto/700-italic.css'
import '@fontsource/josefin-sans/600.css'
import '@fontsource/josefin-sans/600-italic.css'

import { NotificationProvider } from './src/context/Notification'
import React from 'react'
import { ShoppingCartDisplayProvider } from './src/context/ShoppingCartDisplay'
import { ShoppingCartProvider } from './src/context/ShoppingCart'
import { UserProvider } from './src/context/User'
import { WhereToFindProvider } from './src/context/WhereToFind'
import smoothscroll from 'smoothscroll-polyfill'

export const onClientEntry = () => {
  smoothscroll.polyfill()
}
export const wrapRootElement = ({ element }) => {
  return (
    <UserProvider>
      <NotificationProvider>
        <ShoppingCartProvider>
          <WhereToFindProvider>
            <ShoppingCartDisplayProvider>{element}</ShoppingCartDisplayProvider>
          </WhereToFindProvider>
        </ShoppingCartProvider>
      </NotificationProvider>
    </UserProvider>
  )
}

export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    `This website has been updated. Reload to display the latest version?`,
  )
  if (answer === true) {
    window.location.reload()
  }
}

// export const onRouteUpdate = ({ location, prevLocation }) => {
// if (
//   window?.apbrand?.accelpay?.init &&
//   !document.querySelectorAll('#accelpay-iframe').length
// ) {
//   window.apbrand.accelpay.init()
//   // .then(() => {
//   //   const iframes = document.querySelectorAll('#accelpay-iframe')
//   //   if (iframes.length > 1) {
//   //     for (let i = 0; i < iframes.length - 1; i++) {
//   //       if (i !== 0) {
//   //         iframes[i].style['display'] = 'none'
//   //       }
//   //     }
//   //   }
//   // })
// }
// }
