// import React, { Fragment } from 'react'
import { graphql, useStaticQuery } from 'gatsby'

const useMapPins = () => {
  const query = useStaticQuery(
    graphql`
      query AllMapPins {
        allFile(
          filter: { absolutePath: { regex: "//social/pin-(.*).png$/" } }
        ) {
          edges {
            node {
              publicURL
              name
            }
          }
        }
      }
    `,
  )
  const output = {}
  query.allFile.edges.forEach(({ node }) => {
    output[node.name.replace('pin-', '')] = node.publicURL
  })
  return output
}

export default useMapPins
