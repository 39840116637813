import React, { createContext, useEffect, useState } from 'react'

import { v4 as uuidv4 } from 'uuid'

export const NotificationContext = createContext('')

export const NotificationProvider = ({ children }) => {
  const [notificationText, setNotificationText] = useState('')
  // used to trigger animation reflow
  const [notificationKey, setNotificationKey] = useState(uuidv4())

  useEffect(() => {
    const timer = setTimeout(() => {
      setNotificationText('')
    }, 3000)
    return () => clearTimeout(timer)
  }, [notificationText])

  return (
    <NotificationContext.Provider
      value={{
        notificationText,
        setNotificationText,
        notificationKey,
        setNotificationKey,
      }}
    >
      {children}
    </NotificationContext.Provider>
  )
}
