import { graphql, useStaticQuery } from 'gatsby'

const useRecentBlogArticles = () => {
  const query = useStaticQuery(
    graphql`
      query RecentArticlesListQuery {
        allMarkdownRemark(
          filter: { frontmatter: { template: { eq: "blog-post" } } }
          sort: { frontmatter: { date: DESC } }
          limit: 5
        ) {
          edges {
            node {
              fields {
                slug
              }
              frontmatter {
                date
                title
              }
            }
          }
        }
      }
    `,
  )
  return query.allMarkdownRemark.edges
}

export default useRecentBlogArticles
