import { aaronSchema, logoSchema, publisherSchema } from './main'

const article = ({
  headline,
  url,
  origin,
  description,
  image,
  datePublished,
  dateModified,
  sameAs,
}) => {
  const articleSchema = {
    '@context': 'http://schema.org',
    '@type': 'BlogPosting',
    headline,
    url,
    description,
    image: {
      '@type': 'ImageObject',
      url: image.url,
      width: image.width,
      height: image.height,
    },
    author: {
      '@id': '#schemaAaron',
    },
    datePublished,
    dateModified,
    publisher: {
      '@id': '#schemaOtherwiseBrewing',
    },
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': url,
    },
  }

  return JSON.stringify([
    publisherSchema({ url, image, origin, sameAs }),
    articleSchema,
    aaronSchema,
    logoSchema(origin),
  ])
}

export default article
