import schemaArticleTemplate from './article'
import schemaPageTemplate from './page'

const useStructuredData = (data) => {
  const isPage = data.template.indexOf('page') !== -1
  const isBeer = data.template.indexOf('beer') !== -1
  const isArticle = data.template.indexOf('post') !== -1
  const isArchive = data.template.indexOf('archive') !== -1

  if (isPage || isArchive || isBeer) {
    return schemaPageTemplate(data)
  } else if (isArticle) {
    return schemaArticleTemplate(data)
  }
}

export default useStructuredData
